import React from 'react';
import ReactDOM from "react-dom";
import { Normalize } from 'styled-normalize';

import { ApiProvider } from './api';
import { ThemeProvider } from './theme';
import { GlobalStyles } from './styles';
import App from './containers/App';

const Root = () => (
  <ThemeProvider>
    <Normalize />
    <GlobalStyles />
    <ApiProvider>
      <App/>
    </ApiProvider>
  </ThemeProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
